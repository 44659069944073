import '../styles/Kursblock.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

function Kursblock(props)
{
    const Api = "https://www.schmki.online/";
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [KursblockWrapper, setKursblockWrapper] = useState('KursblockWrapper')
    const [KursTable, setKursTable] = useState('KursTable');
    const [TableHeader, setTableHeader] = useState('TableHeader');
    const [TableText, setTableText] = useState('TableText');
    const [TableText0, setTableText0] = useState('TableText0');
    const [TableText2, setTableText2] = useState('TableText');
    const [TableText3, setTableText3] = useState('TableText');
    const [Teilnehmende, setTeilnehmende] = useState(0);
    const [BuchungsStatus, setBuchungsstatus] = useState('');
    const [AnmeldeLink, setAnmeldeLink] = useState('AnmeldeLink');


    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {

        }

        if(windowSize.current[0] < 600)
        {
            setKursblockWrapper('KursblockWrapper_Mobile');
            setKursTable('KursTable_Mobile');
            setTableHeader('TableHeader_Mobile');
            setTableText0('TableText0_Mobile');
            setTableText('TableText_Mobile');
            setTableText2('TableText2_Mobile');
            setTableText3('TableText3_Mobile')
        }

    }, windowSize.current[0]);


    useEffect(()=>{

        if(Teilnehmende < 20)
        {
                setBuchungsstatus("frei");
        }    
        
        if(Teilnehmende == 20)
        {
                setBuchungsstatus("ausgebucht");
                setTableText2('TableText2_full');
                setAnmeldeLink('AnmeldeLink_gone');
                if(windowSize.current[0] < 600)
                    {
                        setTableText2('TableText2_full_Mobile');
                    }
        }

        if(Teilnehmende > 20)
            {
                    setBuchungsstatus("belegt");
                    setTableText2('TableText2_full');
                    setAnmeldeLink('AnmeldeLink_gone');
                    if(windowSize.current[0] < 600)
                        {
                            setTableText2('TableText2_full_Mobile');
                        }
            }
    }, [Teilnehmende])


    useEffect(()=>{
        Axios.post(Api+"giveKursteilnehmer", {KursId:props.KursId}).then((response2) =>{
            setTeilnehmende(response2.data.length);
            console.log(Teilnehmende);
        })
    }, [])

    return(
        <div className={KursblockWrapper}>
            <table className={KursTable} style={{textAlign:"center", backgroundColor:"white"}}>
                <tr style={{backgroundColor:"#e1743c"}}> 
                    <th className={TableHeader}>Titel</th>
                    <th className={TableHeader}>Termin</th>
                    <th className={TableHeader}>Raum</th>
                    <th className={TableHeader}>Status</th>
                    <th className={TableHeader}>Anmelden</th>
                </tr>
                <tr style={{backgroundColor:"white"}}>
                    <td className={TableText0}>{props.Titel}</td>
                    <td className={TableText}>{props.Termin1}</td>
                    <td className={TableText}>{props.Raum1}</td>
                    <td className={TableText2}>{BuchungsStatus}</td>
                    <td className={TableText3}><div className={AnmeldeLink}><Link to="/Anmeldung" state={{Titel:props.Titel, Datum1:props.Termin1, Datum2: props.Termin2, KursId:props.KursId}} style={{textDecoration:"none", color:"white"}}>anmelden</Link></div></td>
                </tr>
                <tr style={{backgroundColor:"white"}}>
                    <td className={TableText0}></td>
                    <td className={TableText}>{props.Termin2}</td>
                    <td className={TableText}>{props.Raum2}</td>
                    <td className={TableText2}></td>
                    <td className={TableText3}></td>
                </tr>
                <tr>
                    
                </tr>
            </table>
        </div>
    
    )
}

export default Kursblock;