import '../styles/Modulubersicht2.css';
import Navigation from '../components/Navigation';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

function Modulubersicht()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ModulFacher, setModulfacher] = useState('Modulfacher');
    const [ModulFacherKomponent, setModulFacherKomponent] = useState('ModulFacherKomponent');
    const [ModulFacherKomponent_Logo, setModulFacherKomponent_Logo] = useState('ModulFacherKomponent_Logo');
    const [ModulFacherKomponent_Titel, setModulFacherKomponent_Titel] = useState('ModulFacherKomponent_Titel');
    const [ModulFacherKomponent_Text, setModulFacherKomponent_Text] = useState('ModulFacherKomponent_Text');
    const [Modulubersicht2_Body, setModulubersicht2_Body] = useState('Modulubersicht2_Body')

    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            setModulFacherKomponent_Titel('ModulFacherKomponent_Titel_4K');
            setModulFacherKomponent_Text('ModulFacherKomponent_Text_4K');
        }

        if(windowSize.current[0] < 1600)
        {
            setModulFacherKomponent_Titel('ModulFacherKomponent_Titel_Medium');
            setModulFacherKomponent_Text('ModulFacherKomponent_Text_Medium');

        }

        if(windowSize.current[0] < 900)
        {
                 
        }

        if(windowSize.current[0] < 600)
        {
            setModulfacher('Modulfacher_Mobile');
            setModulFacherKomponent('ModulFacherKomponent_Mobile');
            setModulubersicht2_Body('Modulubersicht2_Body_Mobile');
            setModulFacherKomponent_Titel('ModulFacherKomponent_Titel_Mobile');
            setModulFacherKomponent_Text('ModulFacherKomponent_Text_Mobile');
            setModulFacherKomponent_Logo('ModulFacherKomponent_Logo_Mobile');
        }

    }, windowSize.current[0]);


    return(
        <div className='ModulubersichtWrapper2'>
                <Navigation Page={"Landingpage"}></Navigation>
                <div className={Modulubersicht2_Body}>
                    <div className={ModulFacher}>
                        <Link to="/Modul1_Info" className={ModulFacherKomponent} style={{color: "black", textDecoration:"none"}}>
                            <div className={ModulFacherKomponent_Logo}><div className='Modul1_Logo'></div></div>
                            <div className={ModulFacherKomponent_Titel}>Modul 1: Das Schreiben vorbereiten</div>
                            <div className={ModulFacherKomponent_Text}>
                                <ul>
                                    <li>Anforderungen von Schreibaufgaben (mit KI) bestimmen</li>
                                    <li>Profilierte Schreibaufgaben kennenlernen und entwerfen</li>
                                    <li>Schreibaufgaben (mit KI) erstellen</li>
                                    <li>Schreibaufgaben (mit KI) reflektieren</li>
                                    <li>Schreibaufgaben (mit KI) differenzieren</li>
                                </ul>
                            </div>
                            </Link>
                        <div className={ModulFacherKomponent}>
                        <div className={ModulFacherKomponent_Logo}><div className='Modul2_Logo'></div></div>
                            <div className={ModulFacherKomponent_Titel}>Modul 2: Das Schreiben begleiten</div>
                            <div className={ModulFacherKomponent_Text}>
                                <ul>
                                    <li>Grundlagen des Schreibprozesses </li>
                                    <li>Schreibprozeduren kennenlernen und einsetzen</li>
                                    <li>KI als Ressource der Textplanung einführen</li>
                                    <li>KI als Formulierungshilfe</li>
                                    
                                </ul>
                            </div>
                        </div>
                        <Link to="/Modul3_Info" className={ModulFacherKomponent} style={{color: "black", textDecoration:"none"}}>
                        <div className={ModulFacherKomponent_Logo}><div className='Modul3_Logo'></div></div>
                            <div className={ModulFacherKomponent_Titel}>Modul 3: Feedback Schreiben nachbereiten </div>
                            <div className={ModulFacherKomponent_Text}>
                                <ul>
                                    <li>Lerndentexte bewusst und kriteriengestützt analysieren</li>
                                    <li>Lernförderliches Feedback formulieren</li>
                                    <li>KI-gestützte Feedback-Tools kennenlernen</li>
                                    <li>Die Potenziale von KI als Ressource reflektieren</li>
                                    
                                </ul>
                            </div>
                        </Link>
                    </div>
                </div>
                <Footer/>      
        </div>
    )
}
export default Modulubersicht;