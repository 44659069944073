import Kursblock from '../components/Kursblock';
import Navigation from '../components/Navigation';
import '../styles/Modulubersicht.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Footer from '../components/Footer';

function Modul1_Info()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ModulHeader, setModulHeader] = useState('ModulHeader');
    const [ModulHeaderElement, setModulHeaderElement] = useState('ModulHeaderElement');
    const [ModulHeaderElementColored, setModulHeaderElementColored] = useState('ModulHeaderElement_Colored');
    const [SlideShow, setSlideShow] = useState('SlideShow');
    const [SlideShowElement0, setSlideShowElement0] = useState('SlideShowElement0');
    const [SlideShowElement1, setSlideShowElement1] = useState('SlideShowElement1');
    const [SlideShowElement2, setSlideShowElement2] = useState('SlideShowElement2');
    const [SlideShowElement3, setSlideShowElement3] = useState('SlideShowElement3');
    const [ModulBeschreibArea, setModulBeschreibArea] = useState('ModulBeschreibArea');
    const [ModulBeschreibAreaText, setModulBeschreibAreaText] = useState('ModulBeschreibAreaText');
    const [ModulBeschreibBox, setModulBeschreibBox] = useState('ModulBeschreibBox');
    const [TermineArea, setTermineArea] = useState('TermineArea');
    const [TermineAreaTitel, setTermineAreaTitel] = useState('TermineAreaTitel');
    const [WorkshopArea, setWorkshopArea] = useState('WorkshopArea');

    const [WorkshopTitel, setWorkshopTitel] = useState([]);
    const [WorkshopId, setWorkshopId] = useState([]);
    const [workshopTermin1, setWorkshopTermin1] = useState([]); 
    const [workshopTermin2, setWorkshopTermin2] = useState([]); 
    const [workshopRaum1, setWorkshopRaum1] = useState([]);
    const [workshopRaum2, setWorkshopRaum2] = useState([]);
    const [WorkshopsPre, setWorkshopsPre] = useState([]);
    const [Workshops, setWorkshops] = useState();

    const Api = "https://www.schmki.online/";

    useEffect(() => {
        Axios.get(Api+'giveTermine',{}).then((response) =>{
            console.log(response);
            if(response)
            {
                for(let w = 0; w < response.data.length; w++)
                    if(response.data[w].KursId < 2000)
                    {
                        
                        WorkshopId.push(response.data[w].KursId);
                        WorkshopTitel.push(response.data[w].Titel);
                        workshopTermin1.push(response.data[w].Termin1);
                        workshopTermin2.push(response.data[w].Termin2);
                        workshopRaum1.push(response.data[w].Raum1);
                        workshopRaum2.push(response.data[w].Raum2);
                    }

                    }
                    {
                for(let w2 = 0; w2 < response.data.length; w2++)
                {
                    if(response.data[w2].KursId < 2000)
                    {
                        WorkshopsPre.push(<Kursblock Titel={WorkshopTitel[w2]} Termin1={workshopTermin1[w2]} Termin2={workshopTermin2[w2]} Raum1={workshopRaum1[w2]} Raum2={workshopRaum2[w2]} KursId={WorkshopId[w2]}></Kursblock>)
                    }
                   
                }

                setWorkshops(WorkshopsPre);                
            }
        })
    })


    useEffect(()=>{

        if(windowSize.current[0] > 2000)
        {
            setModulHeader('ModulHeader_4K');
            setModulHeaderElement('ModulHeaderElement_4K');
            setModulHeaderElementColored('ModulHeaderElement_Colored_4K');
            setModulBeschreibArea('ModulBeschreibArea_4K');
            setModulBeschreibAreaText('ModulBeschreibAreaText_4K');
            setModulBeschreibBox('ModulBeschreibBox_4K');
            setTermineArea('TermineArea_4K');
            setTermineAreaTitel('TermineAreaTitel_4K')
        }

        if(windowSize.current[0] < 1200)
        {
            setModulHeader('ModulHeader_Ipad');
            setModulHeaderElement('ModulHeaderElement_Ipad');
            setModulHeaderElementColored('ModulHeaderElement_Colored_Ipad')
        }

        if(windowSize.current[0] < 900)
            {
                setModulBeschreibBox('ModulBeschreibBox_Ipad_upright');
            }

        if(windowSize.current[0] < 600)
        {
            setModulHeader('ModulHeader_Mobile');
            setModulHeaderElement('ModulHeaderElement_Mobile');
            setModulHeaderElementColored('ModulHeaderElement_Colored_Mobile');
            setSlideShow('SlideShow_Mobile');
            setModulBeschreibArea('ModulBeschreibArea_Mobile');
            setModulBeschreibAreaText('ModulBeschreibAreaText_Mobile');
            setModulBeschreibBox('ModulBeschreibBox_Mobile');
            setTermineArea('TermineArea_Mobile');
            setTermineAreaTitel('TermineAreaTitel_Mobile')
        }

    }, windowSize.current[0]);


    return(
        <div className='Modul3Wrapper'>
            <Navigation Page={"Landingpage"}></Navigation>
        <div className={ModulHeader}>
            <div className={ModulHeaderElement}>Schreiben vorbereiten:</div><div className='Row2'><div className={ModulHeaderElementColored}>Schreibaufgaben</div> <div className={ModulHeaderElement}>mit KI </div></div>
        </div>
        <div className={SlideShow}>
            <div className={SlideShowElement0}></div>
            <div className={SlideShowElement1}></div>
            <div className={SlideShowElement2}></div>
            <div className={SlideShowElement3}></div>
        </div>
        <div className={ModulBeschreibArea}>
            <div className={ModulBeschreibAreaText}>
            <p>Schulisches Lernen erfolgt vielerorts durch das Bearbeiten von Aufgaben. Aus Perspektive der Lehrkräfte wird dabei häufig übersehen, dass die Aufgabe und insbesondere die Schreibaufgabe damit zum zentralen Moment der Einflussnahme auf das Lernen wird. Die Schreibaufgabe ist für das Lernen gewissermaßen das, was der Reifen für das Fahrrad ist: Es ist der Ort, an dem alle Kraftanstrengungen in Vortrieb übersetzt werden, die Kraft 'auf den Boden' gebracht wird. Ein wohlüberlegtes didaktisches Setting verfehlt seine Wirkung, wenn die enthaltene Schreibaufgabe nicht zielführend ist</p>
            <p>Schreibaufgaben zu entwickeln, auszuwählen und zu reflektieren gehört damit zu den tagtäglichen Aufgaben und Herausforderungen von Lehrkräften. Zu überlegen ist damit, ob Künstliche Intelligenz hier helfen kann. Im Internet finden sich zahlreiche KI-gestützte Arbeitsblatt-Generatoren, Bots zur Aufgabenentwicklung und Unterrichtsplanung. Doch lassen sich auf diesem Weg tatsächlich lernförderliche und passgenaue Schreibaufgaben entwickeln? Wann sind Schreibaufgaben überhaupt lernförderlich und zielführend?</p>
        <p>In einem niedrigschwelligen Workshop wollen wir Antworten auf diese (und noch weitere) Fragen finden. Hierbei arbeiten wir mit Materialien aus der Unterrichtspraxis, die für alle Lehramtsfächer relevant sind.
        Der Workshop richtet sich an Lehramtsstudierende sämtlicher Fächer und besteht aus zwei aufeinander aufbauenden Präsenzterminen für jeweils 4 Stunden (2 x 90 Minuten) am Vormittag. Diese werden von einem Online-Angebot gerahmt. Vorkenntnisse werden nicht benötigt (auch nicht im Umgang mit KI). Alle Teilnehmenden bekommen eine schriftliche Teilnahmeurkunde. </p>
        </div>
        <div className={ModulBeschreibBox}>
            <p>Inhalte:</p>
                <ul>
                <li style={{marginTop:1+"%"}}>Schreibaufgaben hinsichtlich ihrer Zielsetzungen unterscheiden</li>
                <li style={{marginTop:1+"%"}}>Herausforderungen in Schreibaufgaben wahrnehmen</li>
                <li style={{marginTop:1+"%"}}>Schreibaufgaben unterstützen und differenzieren</li>
                <li style={{marginTop:1+"%"}}>Aufgaben 'mit Profil' kennenlernen</li>
                <li style={{marginTop:1+"%"}}>KI-Anwendungen: ChatGPT, fobizz-Arbeitsblatt-Generator, schulKI, Lehrer-AI, to teach_ , easy-peasy.ai </li>
                
                </ul>
        </div>
        </div>

        <div className={TermineArea}>
            <div className={TermineAreaTitel}>Termine: </div>
            <div className={WorkshopArea}>{Workshops}</div>
        </div>
        <Footer></Footer>
        </div>
    )
}
export default Modul1_Info;