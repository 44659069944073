import '../styles/FachtagWorkshop.css';
import { useEffect, useRef, useState } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

function FachTagWorkshop()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [FachTagTitelField, setFachTagTitelField] = useState('FachTagTitelField');
    const [FachtagTitel2, setFachtagTitel2] = useState('FachtagTitel2');
    const [FynnTextArea, setFynnTextArea] = useState('FynnTextArea');
    const [FachTagButtonArea, setFachTagButtonArea] = useState('FachTagButtonArea');
    const [FachTagCopyButton, setFachTagCopyButton] = useState('FachTagCopyButton');

    const textToCopy = "Liebe Redaktion \n als ich neulich ihren Artikel über den Schulbeginn gelesen habe, kam mir die Idee Ihnen einen Brief zu schreiben und darin meine Meinung zu sagen. Ich bin der Meinung dass die Schule nicht später anfangen soll, denn dann würde die Schule auch länger dauern und wenn man dann auch noch (wie ich) 8 Stunden hat ist man erst ganz spät zu Hause und dann hat man keine Zeit mehr für andere Sachen (wie z.B. Fußball spielen). Außerdem müssen manche Eltern morgens pünktlich zur Arbeit und wollen ihre Kinder nicht alleine lassen. Wie z.B. bei mir zu Hause meine Eltern müssen um 7:30 zur Arbeit und ich und meine Schwester müssen um diese Zeit dann auch los und wir sollen alleine bleiben. \n Ich komme zu dem Schluss, dass die Schule nicht später anfangen sollte. \n LG \n Fynn";

    useEffect(()=>{
        if(windowSize.current[0] > 1600)
        {
            setFachTagTitelField('FachTagTitelField_large');
            setFachtagTitel2('FachtagTitel2_large');
            setFynnTextArea('FynnTextArea_large');
        }


        if(windowSize.current[0] < 1200)
        {
           
        }

        if(windowSize.current[0] < 600)
        {
            setFachTagTitelField('FachTagTitelField_Mobile');
            setFachtagTitel2('FachtagTitel2_Mobile');
            setFynnTextArea('FynnTextArea_Mobile');
            setFachTagButtonArea('FachTagButtonArea_Mobile');
            setFachTagCopyButton('FachTagCopyButton_Mobile');
        }

    }, windowSize.current[0]);

    const copyText = async () => {
        try {
          await navigator.clipboard.writeText(textToCopy);
          alert("Text wurde in die Zwischenablage kopiert!");
        } catch (err) {
          console.error("Fehler beim Kopieren des Texts: ", err);
        }
      };


    return(
    <div className='FachTagWrapper'>
    <Navigation Page={"Landingpage"}></Navigation>
    <div className='FachTagBody'>
        <div className={FachTagTitelField}>
            <div className='FachtagTitel1'>Fachtag Deutsch:</div>
            <div className={FachtagTitel2}>Text von Fynn:</div>
        </div>
        <div className={FynnTextArea}>
            <p>Liebe Redaktion,</p>
            <p>als ich neulich ihren Artikel über den Schulbeginn 
gelesen habe, kam mir die Idee Ihnen einen             
Brief zu schreiben und darin meine Meinung zu sagen. 
Ich bin der Meinung dass die Schule nicht später 
anfangen soll, denn dann würde die Schule auch 
länger dauern und wenn man dann auch noch (wie ich) 
8 Stunden hat ist man erst ganz spät zu Hause
und dann hat man keine Zeit mehr für andere Sachen 
(wie z.B. Fußball spielen). Außerdem müssen manche 
Eltern morgens pünktlich zur Arbeit und wollen 
ihre Kinder nicht alleine lassen. Wie z.B. bei mir
zu Hause meine Eltern müssen um 7:30 zur Arbeit        
und ich und meine Schwester müssen um diese 
Zeit dann auch los und wir sollen alleine bleiben.
</p>
<p>Ich komme zu dem Schluss, dass die Schule nicht später 
anfangen sollte.
</p>
<p>LG</p>
<p>Fynn</p>

        </div>
        <div className={FachTagButtonArea}>
            <div className={FachTagCopyButton} onClick={copyText}>Text kopieren</div>
        </div>
    </div>
    <Footer/>      

    </div>)
}

export default FachTagWorkshop;