import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import Axios from 'axios';
import Cookies from 'js-cookie'; 


import '../styles/Navigation.css'

function Navigation(props)
{
    const salt = "$2a$10$FyRan35Gah8Thq8%tjUM5x";
    const Api = "https://www.schreibenmitki.de:8443/";
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [NavWrapper, setNavWrapper] = useState('NavWrapper');
    const [LogoArea, setLogoArea] = useState('LogoArea');
    const [LogoTextField, setLogoTextField] = useState('LogoTextField');
    const [LogoText1, setLogoText1] = useState('LogoText1');
    const [LogoText2, setLogoText2] = useState('LogoText2');
    const [SchillerLogo, setSchillerLogo] = useState('SchillerLogo');
    const [NavigationArea, setNavigationArea] = useState('NavigationArea');
    const [NavBar, setNavBar] = useState('NavBar');
    const [MobileNav, setMobileNav] = useState('MobileNav_gone');
    const [NavBarElement, setNavBarElement] = useState('NavBarElement');
    const [MobileNavScreen, setMobileNavScreen] = useState('MobileNavScreen_gone');
    const [MobileLogScreen, setMobileLogScreen] = useState('MobileNavScreen_gone');
    const [MobileWeiterScreen, setMobileWeiterScreen] = useState('MobileNavScreen_gone');
    const [LinkColor, setLinkColor] = useState('white');
    const [LoginOverLay, setLoginOverLay] = useState('LoginOverLay_gone');
    const [showOverlay, setShowOverlay] = useState(false);
    const [Nutzername, setNutzername] = useState(null);
    const [Passwort, setPasswort] = useState(null);
    const [LoginActive, setLoginActive] = useState(false);
    const [LoginOverLayButton, setLoginOverLayButton] = useState('LoginOverLayButton');
    const [LoginOverLayButton2, setLoginOverLayButton2] = useState('LoginOverLayButton_gone');
    const [UserRolle, setUserRolle] = useState('');
    const [LoggedIn, setLoggedIn] = useState(false);
    const [LoginText, setLoginText] = useState('Login');
    const [LoginOverLayInput, setLoginOverLayInput] = useState('LoginOverLayInput');
    const [LoginOverlayMessage, setLoginOverlayMessage] = useState('LoginOverlayMessage_gone');
    const page = props.Page;
    const [MobileNavScreenTitelText, setMobileNavScreenTitelText] = useState("Menü");
    const [MobileLoginButtonText, setMobileLoginButtonText] = useState('Login');
    const [MobileLoginText, setMobileLoginText] = useState('Login');
    const [LinkDestin, setLinkDestin] = useState('/Modul3');

    useEffect(()=>{
        CheckLogin();
        if(page == "Landingpage")
        {

        if(windowSize.current[0] > 2000)
        {
            setLogoArea('LogoArea_4K');
            setNavWrapper('NavWrapper_4K');
            setLogoText1('LogoText1_4K');
            setLogoText2('LogoText2_4K');
            setNavigationArea('NavigationArea_4K');
            setNavBarElement('NavBarElement_4K')
        }
        
        if(windowSize.current[0] < 1200)
        {
            setLogoArea('LogoArea_Ipad');
            setNavWrapper('NavWrapper_Ipad');
            setLogoText1('LogoText1_Ipad');
            setLogoText2('LogoText2_Ipad');
            setNavigationArea('NavigationArea_Ipad');
            setNavBarElement('NavBarElement_Ipad')
        }

        if(windowSize.current[0] < 900)
            {
                setLogoArea('LogoArea_Ipad_upright');
                setLogoText1('LogoText1_Ipad_upright');
                setLogoText2('LogoText2_Ipad_upright');
                setNavigationArea('NavigationArea_Ipad_upright');
            }

        if(windowSize.current[0] < 600)
        {
            setLogoArea('LogoArea_Mobile');
            setNavWrapper('NavWrapper_Mobile');
            setLogoText1('LogoText1_Mobile');
            setLogoText2('LogoText2_Mobile');
            setNavigationArea('NavigationArea_Mobile');
            setNavBar('NavBar_gone');
            setMobileNav('MobileNav');
        }
    }

        if(page != "Landingpage")
        {
            setSchillerLogo('SchillerLogo_Black');
            setLogoText1('LogoText1_black');
            setLogoText2('LogoText2_black')

             if(windowSize.current[0] > 1600)
        {
            setLogoArea('LogoArea_4K');
            setNavWrapper('NavWrapper_4K');
            setLogoText1('LogoText1_black_4K');
            setLogoText2('LogoText2_black_4K');
            setNavigationArea('NavigationArea_4K');
            setNavBarElement('NavBarElement_4K')
        }

            if(windowSize.current[0] < 1200)
            {
            setLogoArea('LogoArea_Ipad');
            setNavWrapper('NavWrapper_Ipad');
            setLogoText1('LogoText1_Ipad_black');
            setLogoText2('LogoText2_Ipad_black');
            setNavigationArea('NavigationArea_Ipad');
            setNavBarElement('NavBarElement_Ipad')
            }

            if(windowSize.current[0] < 900)
                {
                    setLogoArea('LogoArea_Ipad_upright');
                    setLogoText1('LogoText1_Ipad_black_upright');
                    setLogoText2('LogoText2_Ipad_black_upright');
                    setNavigationArea('NavigationArea_Ipad_upright');
                }

        if(windowSize.current[0] < 600)
            {
            setLogoArea('LogoArea_Mobile');
            setNavWrapper('NavWrapper_Mobile');
            setLogoText1('LogoText1_Mobile_black');
            setLogoText2('LogoText2_Mobile_black');
            setNavigationArea('NavigationArea_Mobile');
            setNavBar('NavBar_gone');
            setMobileNav('MobileNav');
            } 
        }

    }, windowSize.current[0]);

    const closeMobileNav = () =>{
        setMobileNavScreen('MobileNavScreen_gone');
        setMobileLogScreen('MobileNavScreen_gone');
    }

    const openMobileNav = () =>{
        setMobileNavScreen('MobileNavScreen');
    }

    const showLoginOverlay = () => {
        if(LoggedIn == false)
            {
                if(showOverlay == false)
                    {
                        setLoginOverLay('LoginOverLay');
                        setShowOverlay(true)
                    }
                    else
                    {
                        setLoginOverLay('LoginOverLay_gone');
                        setShowOverlay(false);
                    }
            }
        else
        {
            Cookies.remove('SchreibenmitKI_UserCode');
            Cookies.remove('SchreibenmitKI_Rolle');
            setLoggedIn(false);
            setLoginText("Login");
            
        }
            
        
    }

    const CheckLogin = () =>{
        if(parseInt(Cookies.get("SchreibenmitKI_UserCode")) > 0)
            {
               setLoggedIn(true);
               setLoginText("Logout");
            }
    }

    

    const doLogin = () => {
        setLoginActive(true);
        setLoginOverLayButton('LoginOverLayButton_gone');

        if(Nutzername == null){
            setLoginOverLay('LoginOverLay_error');
            setTimeout(()=>{ setLoginOverLayButton('LoginOverLayButton');setLoginOverLay('LoginOverLay');},2000);
        }

        if(Passwort == null)
        {
            setLoginOverLay('LoginOverLay_error');
            setTimeout(()=>{ setLoginOverLayButton('LoginOverLayButton');setLoginOverLay('LoginOverLay');},2000);
        }

        console.log(bcrypt.hashSync(Passwort, salt))
        Axios.post(Api+'login', {useruid: Nutzername, password: bcrypt.hashSync(Passwort, salt)}).then((response)=>{

                            Cookies.set("SchreibenmitKI_UserCode", response.data.UserCode);
                            Cookies.set("SchreibenmitKI_Rolle", ""+response.data.UserRolle);
                            setUserRolle(""+response.data.UserRolle);
                    setTimeout(()=>{
                        if(parseInt(Cookies.get("SchreibenmitKI_UserCode")) > 0)
                        {
                            setLoginOverLayInput('LoginOverLay_gone');
                            setLoginText("Logout");
                            setLoginOverLayButton2('LoginOverLayButton');
                            setLoginOverlayMessage('LoginOverlayMessage');
                            
                        }
                        
                        if(Cookies.get("SchreibenmitKI_Rolle") == "Admin")
                        {
                                setLinkDestin('/AdminPage');
                        }
                    },200)
                    if(response.data.message == "Nutzer existiert nicht")
                        {
                            setTimeout(()=>{ setLoginOverLayButton('LoginOverLayButton');setLoginOverLay('LoginOverLay');},2000);
                        }
        }
        
    )
    }

    const showMobileLogin = () =>{
        if(LoggedIn == false)
        {
            setMobileNavScreen('MobileNavScreen_gone');
            setMobileLogScreen('MobileNavScreen');
        }
        else
        {
            Cookies.remove('SchreibenmitKI_UserCode');
            Cookies.remove('SchreibenmitKI_Rolle');
            setLoggedIn(false);
            setMobileLoginText('Login');
        }
       
    }

    const doMobileLogin = () =>{
        setMobileLoginButtonText('');

        if(Nutzername == null){
            setTimeout(()=>{ setMobileLoginButtonText('Login');},2000);
        }

        if(Passwort == null)
        { 
            setTimeout(()=>{  setMobileLoginButtonText('Login');},2000);
        }

        Axios.post(Api+'login', {useruid: Nutzername, password: bcrypt.hashSync(Passwort, salt)}).then((response)=>{
            if(response.data)
                {
                    console.log(response);
                        Cookies.set("SchreibenmitKI_UserCode", response.data.UserCode);
                        Cookies.set("SchreibenmitKI_Rolle", ""+response.data.UserRolle);
                        setUserRolle(""+response.data.UserRolle);
                    setTimeout(()=>{
                    if(parseInt(Cookies.get("SchreibenmitKI_UserCode")) > 0)
                    {   
                        setMobileLoginText('Logout');
                        setMobileNavScreen('MobileNavScreen_gone');
                        setMobileLogScreen('MobileNavScreen_gone');
                        setMobileWeiterScreen('MobileNavScreen');
                    }
                
                    if(Cookies.get("SchreibenmitKI_Rolle") == "Admin")
                    {
                        setLinkDestin('/AdminPage');
                    }
                },200)
                }
                if(response.data.message == "Nutzer existiert nicht")
                {
                    setTimeout(()=>{  setMobileLoginButtonText('Login');},2000);
                }
            })
    }


    return(<div className={NavWrapper}>
        <div className={LogoArea}>
        
            <div className='LogoField'>
            <Link to="/" style={{color: LinkColor, textDecoration:"none"}}>
                <div className={SchillerLogo}>
                </div>
                </Link>
            </div>
            <div className={LogoTextField}>
                
                <div className={LogoText1}>Schreiben mit KI</div>
                <div className={LogoText2}>im Lehramt</div>
                
            </div>
            
        </div>
        <div className= {NavigationArea}>
            <div className={NavBar}>
                <div className={NavBarElement}><Link to="/Projekt" style={{color: LinkColor, textDecoration:"none"}}>Projekt</Link></div>
                <div className={NavBarElement}> <Link to="/Modulubersicht" style={{color: LinkColor, textDecoration:"none"}}>Workshops / Module</Link></div>
                <div className={NavBarElement}>Kontakt</div>
                <div className={NavBarElement} onClick={showLoginOverlay}>{LoginText}</div>
                <div className={LoginOverLay}>
                    <div className='LoginOverlayRow'><input className={LoginOverLayInput} type="text" placeholder='Nutzername' onChange={(e) => setNutzername(e.target.value)} disabled={LoginActive}></input><div className={LoginOverlayMessage}>Login erfolgreich</div></div>
                    <div className='LoginOverlayRow'><input className={LoginOverLayInput} type="password" placeholder='Passwort'  onChange={(e) => setPasswort(e.target.value)} disabled={LoginActive}></input><div className={LoginOverLayButton2}><Link to={LinkDestin} style={{color:"black", textDecoration:"none"}}>Weiter</Link></div></div>
                    <div className='LoginOverlayRow'><div className={LoginOverLayButton} onClick={doLogin}>Login</div></div>
                </div>
                

            </div>
            <div className={MobileNav} onClick={openMobileNav}>=</div>
            <div className={MobileNavScreen}>
                <div className='MobileNavScreenTitel'>Menü</div>
                <Link to="/Projekt" style={{color: LinkColor, textDecoration:"none"}}><div className='MobileNavScreenElement1'>Projekt</div></Link>
                <Link to="/Modulubersicht" style={{color: LinkColor, textDecoration:"none"}}><div className='MobileNavScreenElement2'>Workshops / Module</div></Link>
                <div className='MobileNavScreenElement2'>Kontakt</div>
                <div className='MobileNavScreenElement2' onClick={showMobileLogin}>{MobileLoginText}</div>
                
                <div className='MobileNavClose' onClick={closeMobileNav}>X</div>
            </div>
            <div className={MobileLogScreen}>
                <div className='MobileNavScreenTitel'>Einloggen</div>
               <div className='MobileNavScreenElement1'><input type="text" className='MobileLoginField' placeholder='Nutzername' onChange={(e) => setNutzername(e.target.value)}></input></div>
                <div className='MobileNavScreenElement2'><input type="password" className='MobileLoginField' placeholder='Passwort' onChange={(e) => setPasswort(e.target.value)}></input></div>
                <div className='MobileNavScreenElement2' onClick={doMobileLogin}> {MobileLoginButtonText}</div>
                <div className='MobileNavClose' onClick={closeMobileNav}>X</div>
            </div>
            <div className={MobileWeiterScreen}>
                <div className='MobileNavScreenTitel'>Erfolg!</div>
                <Link to={LinkDestin} style={{textDecoration:"none", color: "white"}}><div className='MobileNavScreenElement1'>Weiter</div></Link>
                <div className='MobileNavClose' onClick={closeMobileNav}>X</div>
            </div>
        </div>
    </div>
    )
}

export default Navigation;