import '../styles/BookletViewer.css';
import BookletModul1 from '../material/Materialheft_SchreibenmitKI_Modul1_Tag1.pdf';

function BookletViewer()
{
    return(
        <div className="BookletViewerWrapper">
            <iframe
            src={BookletModul1}
            width="100%"
            height="auto"
            titel="Booklet Modul1"
            
            ></iframe>

        </div>
    )
}
export default BookletViewer;